import "./index.css";

import "./globals";

import { displayErrorMessageHtml } from "./displayErrorMessageHtml";

type App = (el: HTMLElement) => string | undefined;
type AppLoader = () => Promise<App>;

const root = document.getElementById("main");

const appLoaders = {
  react: async () => (await import("./react/app")).app,
};
export const boot = async (el: HTMLElement, app?: App) => {
  const application = await withDefault(appLoaders.react, app);
  const error = application(el);
  if (error) {
    el.innerHTML = displayErrorMessageHtml(error);
  }
};

const withDefault = async (fallback: AppLoader, app?: App): Promise<App> => {
  if (app) {
    return app;
  }
  return fallback();
};

boot(root);
