// for example: src/globals.ts

// Registers all @oberd/oui with the dom, and augments
// the JSX intrinsic elements type to include our custom elements
// generated from @oberd/oui

import "!css-loader!@oberd/oui/dist/oui/oui.css";
import {
  applyPolyfills,
  defineCustomElements,
  JSX as LocalJSX,
} from "@oberd/oui/loader";
import { HTMLAttributes } from "react";

type StencilToReact<T> = {
  [P in keyof T]?: T[P] &
    Omit<HTMLAttributes<Element>, "className"> & {
      class?: string;
    };
};

declare global {
  export namespace JSX {
    interface IntrinsicElements
      extends StencilToReact<LocalJSX.IntrinsicElements> {}
  }
}
applyPolyfills().then(() => {
  defineCustomElements(window);
});
