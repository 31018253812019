export const displayErrorMessageHtml = (err: string) => {
  return `
<div class="card-preloaded">
    <img src="quality_measures.svg" width="190" />
    <div class="login-card-content-preloaded is-error">
        <h2>Error Encountered in Application</h2>
        <p class="error-message">${err}</p>
        <p>Apologies for the problem. Sometimes it can help to <a onclick="window.location.reload();">reload the application</a>.</p>
    </div>
    <div class="card-footer-preloaded">
        Trouble with Oberd? <a href="https://oberd.jitbit.com">We Can Help</a>
    </div>
</div>
`;
};
